@import '../../variables';

.company-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 160px;
  height: 100vh;
  position: relative;

  .side-line {
    width: 40px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }

  .back-button {
    color: $black;
    text-decoration: none;
    margin-top: 100px;
    font-weight: 500;
    font-size: 20px;
    width: fit-content;
    padding: 20px 0;

    svg {
      margin-right: 15px;
    }
  }

  .content-wrapper {
    height: 80%;

    .company-content {
      margin: 260px 0;

      .company-title-text {
        font-size: 32px;
        font-weight: 700;
      }

      .company-logo {
        height: 100px;
        align-self: flex-start;
        width: 400px;
        object-fit: contain;
        object-position: left;
      }
    }

    .company-title {
      h1 {
        margin-top: 0;
        margin-bottom: 20px;
        font-size: 48px;
      }

      p {
        margin-top: 0;
        margin-bottom: 120px;
        font-size: 20px;
      }
    }

    .people-wrapper {
      width: 100%;
      margin-bottom: 100px;
      margin-top: 100px;

      .people-title {
        font-weight: 700;
      }

      .people-wrapper-inner {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .person {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px 15px;
          background-color: $alt;
          border-radius: 5px;
          justify-content: space-between;

          &:not(:last-child) {
            margin-bottom: 12px;
          }

          &.checkout {
            background-color: rgba($alt, .8);
            pointer-events: none;

            span {
              color: rgba($white, .3);
            }

            .icon-wrapper {
              background-color: rgba($white, .4);
            }
          }

          &:hover {
            cursor: pointer;
          }

          span {
            font-weight: 500;
            color: $white;
            font-size: 18px;
          }

          .icon-wrapper {
            background-color: $white;
            padding: 10px 15px;
            color: $alt;
            border-radius: 5px;
          }
        }
      }
    }
  }

  .about-wrapper {
    height: 20%;

    .about-title {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 20px;
      display: block;
    }

    p {
      margin: 0;
    }
  }
}
@import '../../variables';

.home-screen {
  min-height: 100vh;
  width: 100%;
  padding: 115px 40px 0 40px;
  background-color: $body_bg;

  h1 {
    margin-top: 90px;
    margin-bottom: 40px;
  }

  .company-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    .company-list-item {
      background-color: $white;
      padding: 10px;
      display: flex;
      flex-direction: row;
      border-radius: 4px;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;

      .company-list-description {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        width: 80%;

        span {
          color: $black;
          font-size: 14px;
          font-weight: 700;
        }

        .company-description {
          margin-left: 0;
          font-weight: 400;
          width: 100%;
          text-transform: uppercase;
          font-size: 10px;
        }
      }

      .icon-wrapper {
        background-color: $black;
        padding: 10px;
        color: $white;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          font-size: 20px;
        }
      }
    }
  }
}
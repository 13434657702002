@import '../../variables';

.error-alert {
  height: 70px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: $red;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: $white;
  }
}
.ring-screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .countdown-bar {
    height: 15px;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    animation: slip 10s forwards linear;
  }

  .lottie {
    height: 200px;
  }

  p {
    text-align: center;
    font-size: 20px;
  }
}

@keyframes slip {
  0% {
    width: 100%;
  }

  100% {
    width: 0;
  }
}
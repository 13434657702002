@import '../../variables';

html, body {
  background-color: $body_bg;
}

.overview-screen {
  margin: 0 auto;
  width: 80%;

  h1 {
    text-align: center;
    font-weight: 500;
    margin-top: 100px;
  }

  .company-list {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 18% 18% 18% 18% 18%;
    width: 100%;
    margin-top: 80px;
    margin-bottom: 80px;

    .company-list-item {
      background-color: $white;
      padding: 50px 35px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &:hover {
        cursor: pointer;
      }

      img {
        height: 70%;
      }
    }
  }
}